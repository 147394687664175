<template>
  <v-select
    v-model="plant"
    :items="plantItems"
    background-color="transparent"
    dark
    color="white"
    hide-details
    filled
    label="Fabrika Seçimi"
    :prepend-inner-icon="icons.mdiFactory"
  ></v-select>
</template>

<script>
import store from '@/store'
import { mdiFactory } from '@mdi/js'

import { onMounted, ref } from '@vue/composition-api'

export default {
  setup() {
    const plantItems = ref([])
    const plant = ref('01')
    onMounted(() => {
      store.dispatch('app-order/fetchPlants').then(response => {
        const plantList = Object.entries(response.plants).map(item => ({ value: item[0], text: item[1] }))

        plantItems.value = plantList
      })
    })

    return {
      plantItems,
      plant,
      icons: { mdiFactory },
    }
  },
}
</script>
