<template>
  <v-row>
    <v-col cols="12" :class="status ? 'px-3 px-lg-3' : null">
      <div v-if="status && statusCustomer && !maintenanceMode" id="order-list">
        <v-progress-circular v-if="pageLoading" class="el-center" :size="120" indeterminate>
          <span class="text-caption">Yükleniyor</span>
        </v-progress-circular>
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" class="pb-3" />

        <order-header></order-header>
        <order-content
          class="px-3"
          :kondisyon-deger="kondisyonDeger"
          :tip-deger="tipDeger"
          :kondisyon-items="kondisyonItems"
          @changeStatus="pageLoadingStatus"
        ></order-content>
        <order-footer></order-footer>
      </div>
      <div v-else>
        <div v-if="maintenanceMode">
          <v-alert type="error" prominent outlined rounded="pill">
            Şu anda güncelleme yapıladığından dolayı satış yapılamaktadır.
          </v-alert>
        </div>
        <div v-else>
          <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />

          <v-alert
            v-if="!$store.state.plasiyerCustomer"
            text
            outlined
            prominent
            class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0"
            :type="loadingKota ? 'info' : 'warning'"
          >
            <div v-if="loadingKota">Lütfen bekleyin kota bilgileri güncelleniyor</div>
            <div v-else>Lütfen yukarıdan bir müşteri seçin</div>
          </v-alert>
          <div v-else>
            <v-row>
              <v-col>
                <v-card
                  :loading="limitLoading || loadingKota"
                  :disabled="limitLoading || loadingKota"
                  :elevation="$vuetify.breakpoint.smAndDown ? '0' : '3'"
                  rounded="xl"
                  class="mx-auto mt-md-9"
                  :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
                  :max-width="$vuetify.breakpoint.smAndDown ? '92%' : 600"
                >
                  <v-img
                    class="white--text align-center"
                    :class="$vuetify.breakpoint.smAndDown ? 'rounded-xl' : 'rounded-xl'"
                    src="@/assets/images/pages/header-01.jpg"
                    gradient="to bottom, rgba(0,0,0,0), rgba(49,45,75,1)"
                  >
                    <div class="px-6">
                      <div class="pt-5 pb-3">
                        <div class="text-h6">İlave Bilgiler</div>
                        <div class="text-caption">Lütfen Aşağıdan Seçimlerinizi Yapınız</div>
                      </div>
                      <v-alert v-if="limitError" type="error" rounded="lg" prominent class="mb-5">
                        <h4>Hata oluştu lütfen tekrar deneyin</h4>
                        <small>Pasif müşteri seçtiniz lütfen aktif bir müşteri seçin</small>

                      </v-alert>
                      <v-alert
                        v-else
                        :type="limitLoading ? 'info' : openrisk <= 0 ? 'error' : 'success'"
                        prominent
                        :text="openrisk > 0"
                        rounded="lg"
                        class="mb-5"
                      >
                        <h3 v-if="limitLoading" class="ml-3">Limit verileri bekleniyor</h3>
                        <div v-else class="ml-3 mt-2">
                          <h3 style="line-height: 0.75; color: white !important">{{ openrisk | currency }}</h3>
                          <small>Güncel Limit</small>
                        </div>
                      </v-alert>

                      <v-select v-model="tipDeger" label="Sipariş Tipi Seçiniz" :items="tipItems" @change="tipChange">
                      </v-select>

                      <v-select
                        v-if="tipDeger === 'O9' && baglantiTipItems.length > 0"
                        v-model="baglantiTipDeger"
                        :items="baglantiTipItems"
                        @change="baglantiTipChange"
                        label="Bağlantı Tipi Seçiniz"
                        return-object
                        item-text="stext"
                      >
                      </v-select>
                      <v-alert dense :type="hasBaglanti" text v-else-if="tipDeger === 'O9'">{{ baglantiText }}</v-alert>
                   
                      <v-select
                        v-if="tipDeger != 'O9' && tipDeger != '' && activiteItems.length > 0"
                        v-model="activiteDeger"
                        label="Aktivite Seçiniz"
                        :items="activiteItems"
                        @change="activiteChange"
                      >
                      </v-select>

                      <v-select
                        v-model="kotaDeger"
                        v-if="tipDeger != 'O9' && tipDeger != ''"
                        label="Kota Seçiniz"
                        :items="kotaItems"
                        @change="kotaChange"
                      >
                        <template v-slot:item="data">
                          <div class="my-3">
                            <div>
                              {{ data.item.text }}
                            </div>
                            <div v-if="data.item.limit > 0" class="text-caption">
                              <div class="secondary--text mr-3">
                                Limit : {{ data.item.limit | currency({ symbol: 'KG', fractionCount: '0' }) }}
                              </div>
                              <div class="warning--text mr-3">
                                Kullanılan :
                                {{ data.item.sKullanilan | currency({ symbol: 'KG', fractionCount: '0' }) }}
                              </div>
                              <div class="error--text">
                                Kalan :
                                {{
                                  (data.item.limit - data.item.sKullanilan)
                                    | currency({ symbol: 'KG', fractionCount: '0' })
                                }}
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-slot:selection="data">
                          <div>
                            {{ data.item.text }}
                          </div>
                        </template>
                      </v-select>

                      <v-select
                        v-model="kondisyonDeger"
                        v-if="tipDeger != 'O9' && tipDeger != ''"
                        :disabled="!tipDeger"
                        label="Ödeme Tipi Seçiniz"
                        :items="kondisyonItems"
                        @change="kondisyonChange"
                      />

                      <v-btn
                        :disabled="limitError"
                        block
                        depressed
                        rounded
                        color="tertiary"
                        x-large
                        class="my-6"
                        @click="ilaveDevam"
                      >
                        Devam
                      </v-btn>
                    </div>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import useAppConfig from '@core/@app-config/useAppConfig'
import {
  mdiAccount,
  mdiEye,
  mdiFileExcel,
  mdiFilter,
  mdiFilterOff,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiMagnify,
  mdiMessage,
  mdiPencil,
  mdiPlus,
  mdiPrinter,
  mdiSend,
  mdiShare,
  mdiShield,
  mdiShieldHalfFull,
  mdiSquareEditOutline,
  mdiWeatherNight,
  mdiWeatherSunny,
} from '@mdi/js'
import { postData } from '@utils'
import { onMounted, ref, watch } from '@vue/composition-api'

import CustomerList from '@/components/CustomerList.vue'
import orderStoreModule from '../orderStoreModule'
import OrderContent from './order-components/OrderContent.vue'
import OrderFooter from './order-components/OrderFooter.vue'
import OrderHeader from './order-components/OrderHeader.vue'
import Vue from 'vue'
export default {
  components: {
    OrderHeader,
    OrderContent,
    OrderFooter,
    CustomerList,
  },
  setup() {
    const ORDER_APP_STORE_MODULE_NAME = 'app-order'
    const { isDark } = useAppConfig()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const status = ref(false)

    const pageLoading = ref(true)

    // Register module
    if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) {
      store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)
    }

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_APP_STORE_MODULE_NAME)
    // })
    const statusCustomer = ref(false)
    const kotaItems = ref([])
    const kotaDeger = ref()
    const kondisyonItems = ref([])
    const kondisyonDeger = ref()
    const tipItems = ref([
      { text: 'Ön Ödemeli', value: 'O1P' },
      { text: 'Normal', value: 'O1' },
      { text: 'Bağlantılı', value: 'O9' },
    ])
    const tipDeger = ref('')

    const baglantiTipDeger = ref({})
    const baglantiTipItems = ref([])

    const loadingKota = ref(true)
    const loadingConditions = ref(true)

    const plasyirFn = () => {
      loadingKota.value = true
      loadingConditions.value = true

      postData({
        method: 'getKotaList',
        delivery_date: store.state['app-order'].deliveryDate,
      }).then(response => {
        kotaItems.value = []
        if (response.error === 0) {
          kotaItems.value = response.response.map(({ limit, sKullanilan, limitNum, notes }) => ({
            text: notes,
            value: limitNum,
            limit,
            sKullanilan,
          }))
        }
        kotaItems.value.unshift({
          text: 'Kota Kullanmadan Devam Et',
          value: '0',
        })
        loadingKota.value = false
      })

      postData({
        method: 'getMemberPaymentConditions',
        customer: store.state.plasiyerCustomer,
      }).then(response => {
        if (response.error) {
          return
        }
        kondisyonItems.value = []
        kondisyonItems.value = response.map(({ paymcondtxt, paymcond }) => ({
          text: paymcondtxt,
          value: paymcond,
        }))
      })
      loadingConditions.value = false
    }
    const maintenanceMode = ref(false)
    onMounted(() => {
      postData({
        method: 'checkMaintenance',
      }).then(response => {
        if (response.error) {
          return
        }

        maintenanceMode.value = response.maintenance_mode
      })

      if (userData.role === 'PLSYR') {
        plasyirFn()
      }
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else if (userData.role === 'CUST') {
        status.value = true
        statusCustomer.value = true
      }

      if (store.state.plasiyerCustomer) {
        limitCheck()
      }
    })

    const trashBasket = () => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Kota değişikliğinden sonra sepetiniz temizlenir. Devam etmek istiyor musunuz?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order/fetchTrashBasket').then(() => {
            store.dispatch('app-order/fetchActiveBasketList').then(response => {
              store.commit('app/TOGGLE_BASKET_DRAWER', false)
              Vue.swal({
                title: 'Sepet Temizlendi',
                text: 'İşlem başarılı',
                icon: 'success',
                timer: 1500,
                background: '#56CA00',
                timerProgressBar: true,
                showConfirmButton: false,
              })
              postData({
                method: 'setMemberKota',
                limitnum: kotaDeger.value,
                delivery_date: store.state['app-order'].deliveryDate,
              }).then(response => {})
            })
          })
        } else {
          kotaDeger.value = ''
        }
      })
    }

    const trashBasketBaglanti = () => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Sepetinizde sadece bağlantılı ürünler olması gerektiği için sepetiniz temizlenecek. Devam etmek istiyor musunuz?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order/fetchTrashBasket').then(() => {
            store.dispatch('app-order/fetchActiveBasketList').then(response => {
              store.commit('app/TOGGLE_BASKET_DRAWER', false)
              Vue.swal({
                title: 'Sepet Temizlendi',
                text: 'İşlem başarılı',
                icon: 'success',
                timer: 1500,
                background: '#56CA00',
                timerProgressBar: true,
                showConfirmButton: false,
              })
              postData({
                method: 'setLinkedOrder',
                doctype: baglantiTipDeger.value.doctype,
                docnum: baglantiTipDeger.value.docnum,
              }).then(response => {
                kondisyonDeger.value = response.detail.PAYMCOND
              })
            })
          })
        } else {
          baglantiTipDeger.value = ''
        }
      })
    }

    const kotaChange = () => {
      if (store.state['app-order'].basketItemCount > 0) {
        trashBasket()
      } else {
        postData({
          method: 'setMemberKota',
          limitnum: kotaDeger.value,
          delivery_date: store.state['app-order'].deliveryDate,
        }).then(response => {})
      }
    }

    const kondisyonChange = () => {
      postData({
        method: 'setMemberCondition',
        condition: kondisyonDeger.value,
      }).then(response => {})
    }
    const hasBaglanti = ref('info')
    const baglantiText = ref('Kontrol Ediliyor')
    const tipChange = () => {
      hasBaglanti.value = 'info'
      baglantiText.value = 'Kontrol Ediliyor'

      postData({
        method: 'changeOrderType',
        orderType: tipDeger.value,
      }).then(response => {
        // kondisyonItems
        baglantiTipItems.value = []
        if (response.text === 'Bağlantılı Anlaşmalar') {
          baglantiTipItems.value = response.detail
        } else {
          hasBaglanti.value = 'warning'
          baglantiText.value = 'Listelenecek anlaşma bulunamadı'
        }
        store.commit('PAYMENT_TYPE', tipDeger.value)
        kondisyonItems.value = []
        kondisyonDeger.value = null
        for (const [key, value] of Object.entries(response.detail)) {
          kondisyonItems.value.push({
            text: value,
            value: key,
          })
        }
      })
    }

    const baglantiTipChange = () => {
      if (store.state['app-order'].basketItemCount > 0) {
        trashBasketBaglanti()
      } else {
        postData({
          method: 'setLinkedOrder',
          doctype: baglantiTipDeger.value.doctype,
          docnum: baglantiTipDeger.value.docnum,
        }).then(response => {
          kondisyonDeger.value = response.detail.PAYMCOND
        })
      }
    }
    const pageLoadingStatus = () => {
      pageLoading.value = false
    }

    const limitLoading = ref(true)
    const limitError = ref(false)
    const openrisk = ref(0)

    const limitCheck = () => {
      limitLoading.value = true
      limitError.value = false
      postData({
        method: 'getCurrentRiskAndLimit',
      })
        .then(response => {
          if (response.error) {
            limitError.value = true
          } else {
            openrisk.value = response.detail.openrisk
          }
          limitLoading.value = false
        })
        .catch(err => {
          limitError.value = true
          loadingConditions.value = false
          loadingKota.value = false
          limitLoading.value = false
        })
    }

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        kondisyonItems.value = []
        kotaItems.value = []
        activiteDeger.value = []
        activiteItems.value = ''
        kondisyonDeger.value = ''
        kotaDeger.value = ''
        tipDeger.value = ''
        statusCustomer.value = false
        plasyirFn()
        activiteChange()
        limitCheck()
      },
    )
    const isAddNewUserSidebarActive = ref(false)

    const ilaveDevam = () => {
      if (kotaDeger.value && kondisyonDeger.value && tipDeger.value) {
        status.value = true
        statusCustomer.value = true
      }
      if (tipDeger.value == 'O9' && baglantiTipDeger.value.doctype) {
        status.value = true
        statusCustomer.value = true
      }
    }

    const activiteDeger = ref(null)
    const activiteItems = ref([])
    const activiteChange = () => {
      if (activiteItems.value.length === 0) {
        postData({
          method: 'getAktiviteList',
          delivery_date: store.state['app-order'].deliveryDate,
        }).then(response => {
          if (typeof response.response !== 'undefined') {
            activiteItems.value = response.response.map(item => {
              return {
                value: item.ANLASMAID,
                text: item.STEXT,
              }
            })
          }
        })
      } else {
        postData({
          method: 'setMemberAktivite',
          anlasmaid: activiteDeger.value,
          delivery_date: store.state['app-order'].deliveryDate,
        }).then(response => {})
      }
    }
    onMounted(() => {
      activiteChange()
    })
    return {
      baglantiText,
      hasBaglanti,
      activiteDeger,
      activiteItems,
      activiteChange,
      maintenanceMode,
      loadingConditions,
      loadingKota,
      limitLoading,
      limitError,
      openrisk,
      isDark,
      isAddNewUserSidebarActive,
      status,
      pageLoading,
      pageLoadingStatus,
      userData,
      statusCustomer,
      kotaDeger,
      kotaChange,
      kotaItems,
      kondisyonItems,
      kondisyonDeger,
      kondisyonChange,
      tipItems,
      tipDeger,
      tipChange,
      baglantiTipItems,
      baglantiTipDeger,
      baglantiTipChange,
      ilaveDevam,

      // icons
      icons: {
        mdiFileExcel,
        mdiMessage,
        mdiWeatherNight,
        mdiWeatherSunny,
        mdiSquareEditOutline,
        mdiPencil,
        mdiPrinter,
        mdiAccount,
        mdiPlus,
        mdiEye,
        mdiMagnify,
        mdiShare,
        mdiFilter,
        mdiFullscreen,
        mdiFullscreenExit,
        mdiShield,
        mdiSend,
        mdiShieldHalfFull,
        mdiFilterOff,
      },
    }
  },
  data() {
    return {
      date: null,
      modal: false,
      fullscreenStatus: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: 87,
        footer: 47,
      },
    }
  },
}
</script>
