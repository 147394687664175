<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-select
        v-model="category"
        v-bind="attrs"
        label="Ürün Kategorileri"
        :prepend-inner-icon="icons.mdiFileTree"
        dark
        background-color="transparent"
        filled
        color="white"
        :items="['Tümü']"
        hide-details
        v-on="on"
      >
      </v-select>
    </template>
    <v-sheet>
      <v-text-field
        v-model="search"
        label="Kategoriler"
        filled
        class="rounded-t-lg"
        hide-details
        :prepend-inner-icon="icons.mdiFileTree"

        clearable
      ></v-text-field>
      <v-treeview
        class="pt-2 pr-2 pb-3"
        shaped
        hoverable
        active-class="v-treeview-node--active "
        activatable
        item-key="name"
        return-object
        :items="categorys"
        :search="search"
      >
        <template
          slot="label"
          slot-scope="props"
        >
          <router-link
            class="link_full"
            :to="{ name: 'product-list-category', params: { categorySlug: props.item.slug, categoryId: props.item.id }}"
          ></router-link>
          <span class="text-decoration-none text-body-2 text-capitalize ">{{ props.item.name }}</span>
        </template>
      </v-treeview>
    </v-sheet>
  </v-menu>
</template>

<script>
import store from '@/store'
import { mdiFileTree } from '@mdi/js'
import { convertDataTree } from '@utils'
import { onMounted, ref } from '@vue/composition-api'

export default {
  setup() {
    const category = ref('Tümü')
    const categorys = ref([])
    const open = ref(false)
    const search = ref(null)
    onMounted(() => {
      store.dispatch('app-order/fetchProductCategory').then(response => {
        const categoryList = response.categoryList.map(item => {
          const items = {}
          let status = false
          if (item.aktif === 1) {
            status = true
          } else {
            status = false
          }
          items.id = Number(item.id)
          items.status = status
          items.parent_id = Number(item.ust)
          items.order = Number(item.sira)
          items.name = item.kategori.toLowerCase()
          items.slug = item.slug.replace('urunler/', '')

          return items
        })

        categorys.value = convertDataTree(categoryList)
        open.value = true
      })
    })

    return {
      open,
      search,
      category,
      categorys,
      icons: { mdiFileTree },
    }
  },
}
</script>
